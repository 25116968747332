@font-face {
  font-family: 'Axiforma';
  src: url(./assets/fonts/AxiformaThin.otf) format('opentype');
  font-weight: 100;
}

@font-face {
  font-family: 'Axiforma';
  src: url(./assets/fonts/AxiformaLight.otf) format('opentype');
  font-weight: 200;
}

@font-face {
  font-family: 'Axiforma';
  src: url(./assets/fonts/AxiformaBook.otf) format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'Axiforma';
  src: url(./assets/fonts/AxiformaRegular.otf) format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Axiforma';
  src: url(./assets/fonts/AxiformaMedium.otf) format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Axiforma';
  src: url(./assets/fonts/AxiformaSemiBold.otf) format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'Axiforma';
  src: url(./assets/fonts/AxiformaBold.otf) format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'Axiforma';
  src: url(./assets/fonts/AxiformaExtraBold.otf) format('opentype');
  font-weight: 800;
}
